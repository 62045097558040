import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import Features from "../components/Features";

// eslint-disable-next-line

const IndexPage = ({ data }) => {
  const { edges: photoshoots } = data.allMarkdownRemark;

  return (
    <Layout>
      <Features photoshoots={photoshoots}/>
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query MyQuery {
    allMarkdownRemark(
      filter: {frontmatter: {Photoshoot: {}, featured: {eq: true}}}
      sort: {fields: frontmatter___date, order: DESC}
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            description
            Photoshoot {
              photos {
                image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;